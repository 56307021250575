<template>
  <div class="s2">
    <nav-header></nav-header>

    <div class="container">
      <!-- 标题 -->
      <div class="y-title">
        <h2>{{ this.title }}</h2>
        <div class="t-info">
          <div class="blink"></div>
          <span>Hot products</span>
          <div class="blink"></div>
        </div>
      </div>

      <!-- 容器 -->
      <div class="s2-con">
        <!-- 左侧 -->
        <div class="s2-l">
          <img :src="this.img" alt="" />
        </div>
        <!-- 右侧 -->
        <div class="s2-r">
          <h2>{{ this.id }}</h2>
          <h4>{{ this.article1 }}</h4>
          <h4>{{ this.article2 }}</h4>
          <p>
            {{ this.article3 }}
          </p>
          <p>
            {{ this.article4 }}
          </p>
          <p>
            {{ this.article5 }}
          </p>
        </div>
      </div>
    </div>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import NavFooter from "./../components/NavFooter";
import NavHeader from "./../components/NavHeader";
export default {
  components: {
    NavFooter,
    NavHeader,
  },
  data() {
    return {
      // 获取 id
      myId: this.$route.query.type,
      // 获取 标题
      title: "",
      // 简介
      concise: "",
      // 作者
      author: "",
      // 图片路径
      img: "",

      // 文章1
      article1: "",
      // 文章2
      article2: "",
      article3: "",
      article4: "",
      article5: "",
      time: "",
      ac3: [],
      al: [],
    };
  },
  mounted() {
    this.init();
    this.init2();
    document.title = "上海优创融联计算机科技有限公司";
  },
  methods: {
    init() {
      this.axios.get("/mock/yw.json").then((res) => {
        var vl = res.data;
        var date = vl.find((vl) => vl.id == this.myId);
        // console.log(date);
        // console.log(date)
        // 标题
        this.title = date.title;
        // 作者
        // this.author = date.author;
        // 歌词
        // this.concise = date.concise;
        // 图片路径
        this.img = date.imgs;
        // 文章 1
        this.article1 = date.p1;
        // 文章 2
        this.article2 = date.p2;
        this.article3 = date.p3;
        this.article4 = date.p4;
        this.article5 = date.p5;
        // this.time = date.time;
        // this.ac3 = vl.slice(0, 7);
      });
    },
    init2() {
      this.axios.get("/mock/al.json").then((res) => {
        const data = res.data;
        console.log(data);
        this.al = data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.s2 {
  background-color: #fafafa;
  // banner
  .banner {
    width: 100%;
    height: 400px;
    background-color: pink;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .container {
    // 标题
    .y-title {
      width: 100%;
      text-align: center;
      margin: 30px auto;
      margin-top: 60px;
      h2 {
        font-size: 24px;
        font-weight: 400px;
      }
      .t-info {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-size: 16px;
          color: #888;
          margin: 0 10px;
        }
        .blink {
          width: 60px;
          height: 2px;
          background-color: $colorZ;
        }
      }
    }
    //   容器
    .s2-con {
      width: 100%;
      //   height: 450px;
      //   background-color: pink;
      margin: 60px auto;
      @include flex();
      // 左侧
      .s2-l {
        width: 50%;
        // height: 100%;
        height: 450px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      // 右侧
      .s2-r {
        width: 50%;
        height: 100%;
        padding: 30px;
        box-sizing: border-box;
        h2 {
          font-size: 28px;
          font-weight: 500;
          color: #333;
        }
        h4 {
          font-size: 18px;
          color: #333;
          margin: 5px 0;
          margin-bottom: 20px;
        }

        p {
          font-size: 12px;
          text-indent: 20px;
          color: #888;
          line-height: 30px;
        }
      }
    }

    // 案例容器
    .s2-list {
      width: 100%;
      height: 500px;
      //   background-color: pink;
      margin: 30px auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .s2-item {
        img {
          width: 300px;
          height: 180px;
          // background-color: #c60023;
          margin: 0 20px;
        }
        p {
          font-size: 16px;
          text-align: center;
          font-weight: bold;
          margin-top: 5px;
        }
      }
    }
  }
}
</style>