<template>
  <div class="js">
    <div class="container">
      <!-- 右侧 -->
      <div class="js-r">
        <h2>北京锐利思管理咨询有限公司</h2>
        <p>
          北京锐利思管理咨询有限公司是一家具备全业务运营能力的管理咨询服务公司。我们具有成熟的运营模式、先进的技术平台，优秀的研发和服务团队，经过近几年的发展，凭借技术核心优势和崭新的服务理念为客户提供统服务。
        </p>
        <p>
          公司主要为企业提供通信行业的整体解决方案的专业机构，我们专注管理咨询20年，是中国较早的战略咨询服务机构。。我们以最优的产品、解决方案及个性化专业服务得到更多用户认可，成为更多企业首选的咨询、培训领域的供应商。我们拥有一支高素质的专业技术队伍和管理团队，包括多名培训咨询专家、数据库领域专家、系统架构师及高级研发人员。
        </p>

        <p>
          我们的管理咨询服务覆盖房地产、科技、再生资源、软件等广泛的各行各业。公司一直秉承“深解政策、深谙行业、跨界合作”的经营服务模式，以培训服务为痛点切入，创新思维整合行业资源，开创了一条特色的服务体系，服务能力转型升级，业务发展层次全面提升。
        </p>
      </div>
      <!-- 左侧 -->
      <div class="js-l">
        <img src="/imgs/js1.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.js {
  width: 100%;
  height: 360px;
  margin: 50px auto;

  // background: pink;
  .container {
    @include flex();

    height: 100%;

    .js-l {
      width: 50%;
      height: 100%;
      background: #c59012;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .js-r {
      width: 50%;
      height: 100%;
      background-color: #fff;
      margin-left: 30px;
      padding: 20px;
      box-sizing: border-box;

      h2 {
        font-size: 21px;
        font-weight: 400;
        color: #333;
      }

      p {
        font-size: 14px;
        color: #888;
        line-height: 24px;
        margin: 7px 0;
        text-indent: 20px;
      }
    }
  }
}
</style>