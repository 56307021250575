<template>
  <div class="info">
    <nav-header></nav-header>
    <!-- 定宽 -->
    <div class="container">
      <!-- 左侧 导航 -->

      <!-- 右侧 内容 -->
      <div class="info-2">
        <h3>北京锐利思管理咨询有限公司</h3>
        <!-- 下环线 -->
        <div class="bink"></div>

        <div class="i2-con">
          <p>
            北京锐利思管理咨询有限公司是一家具备全业务运营能力的管理咨询服务公司。我们具有成熟的运营模式、先进的技术平台，优秀的研发和服务团队，经过近几年的发展，凭借技术核心优势和崭新的服务理念为客户提供统服务。
          </p>
          <p>
            公司主要为企业提供通信行业的整体解决方案的专业机构，我们专注管理咨询20年，是中国较早的战略咨询服务机构。。我们以最优的产品、解决方案及个性化专业服务得到更多用户认可，成为更多企业首选的咨询、培训领域的供应商。我们拥有一支高素质的专业技术队伍和管理团队，包括多名培训咨询专家、数据库领域专家、系统架构师及高级研发人员。
          </p>

          <p>
            我们的管理咨询服务覆盖房地产、科技、再生资源、软件等广泛的各行各业。公司一直秉承“深解政策、深谙行业、跨界合作”的经营服务模式，以培训服务为痛点切入，创新思维整合行业资源，开创了一条特色的服务体系，服务能力转型升级，业务发展层次全面提升。
          </p>

          <p>网站域名：yogou.shop</p>
          <p>联系座机：010-62923115</p>
          <p>
            网站备案：<a href="https://beian.miit.gov.cn/#/Integrated/index">******</a>
          </p>
          <p>联系地址：北京市海淀区北清路164号28-38号院2222号</p>
        </div>
      </div>
    </div>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import NavFooter from "./../components/NavFooter";
import NavHeader from "./../components/NavHeader";
export default {
  name: "info",
  components: {
    NavFooter,
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "锐利思";
  },
  methods: {
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 跳转注册协议
    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.info {
  width: 100%;
  box-sizing: border-box;
  background-color: #f0f0f0;

  // banner
  .i-banner {
    width: 100%;
    height: 250px;
    background-color: pink;
    margin-bottom: 120px;
  }

  // 定宽布局
  .container {
    // @include flex();
    display: flex;
    justify-content: space-between;

    // height: 2000px;
    // 左侧
    .info-1 {
      width: 30%;
      height: 100%;
      margin-right: 20px;

      // background-color: red;
      ul {
        width: 190px;
        background-color: #fff;
        border-bottom: 2px solid #dfe1e2;
        border-radius: 3px;
        padding: 13px 15px;
        margin: 0 auto;

        li {
          width: 90%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-weight: bold;

          box-sizing: border-box;
          margin: 10px auto;
          cursor: pointer;
          padding-left: 20px;

          &.active {
            background-color: #3890e1;
            color: #fff;
          }
        }
      }
    }

    // 右侧
    .info-2 {
      width: 100%;
      height: 100%;
      background: #fff;
      padding-top: 20px;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 60px;

      h3 {
        font-size: 24px;
      }

      // 下划线
      .bink {
        width: 30px;
        height: 3px;
        background-color: #000;
        margin: 10px auto;
      }

      // banner
      .i2-banner {
        width: 100%;
        height: 300px;
        background: url("/imgs/b1.jpg") no-repeat center;
        background-size: cover;
        margin: 30px auto;
      }

      // 文章
      .i2-con {
        // width: 600px;
        text-align: left;
        padding: 0 20px;
        box-sizing: border-box;

        p {
          font-size: 16px;
          line-height: 1.3em;
          color: #666;
          margin: 15px auto;
          text-indent: 30px;
          line-height: 41px;

          a {
            color: #666;
          }
        }
      }
    }
  }
}</style>
