<template>
  <div class="n-footer">
    <div class="container">
      <!-- logo -->
      <div class="f-logo">
        <h2>北京锐利思管理咨询有限公司</h2>
      </div>

      <!-- 介绍 -->
      <div class="f-jj">
        <h2>公司介绍</h2>
        <p>
          北京锐利思管理咨询有限公司是一家具备全业务运营能力的管理咨询服务公司。我们具有成熟的运营模式、先进的技术平台，优秀的研发和服务团队，经过近几年的发展，凭借技术核心优势和崭新的服务理念为客户提供统服务。
        </p>
      </div>
      <!-- 信息 -->
      <div class="f-info">
        <p>公司名称：北京锐利思管理咨询有限公司</p>
        <!-- <p>网站邮箱：519835146@qq.com</p> -->
        <p>联系座机：010-62923115</p>
        <p>网站域名：yogou.shop</p>
        <p>地址：北京市海淀区北清路164号28-38号院2222号</p>
        <p>
          网站备案：<a href="https://beian.miit.gov.cn/#/Integrated/index">******</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.n-footer {
  width: 100%;
  height: 200px;
  background-color: #292f32;

  .container {
    height: 100%;
    @include flex();

    .f-logo {
      width: 300px;
      height: 100%;

      h2 {
        font-size: 18px;
        color: #fff;
        line-height: 200px;
      }
    }

    .f-jj {
      width: 300px;

      //   height: 100%;
      h2 {
        font-size: 16px;
        color: #fff;
        margin-bottom: 20px;
      }

      p {
        font-size: 12px;
        color: #888;
        display: -webkit-box;
        line-height: 20px;
        text-indent: 30px;
      }
    }

    .f-info {
      width: 400px;

      p {
        font-size: 12px;
        color: #888;
        margin: 15px 0;

        a {
          color: #888;
          font-size: 12px;
        }
      }
    }
  }
}
</style>