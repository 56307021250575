<template>
  <div class="n-header">
    <!-- 导航层 -->
    <div class="n-n">
      <div class="container">
        <!-- logo -->
        <div class="n-logo">
          <a href="/" style="color: #333"><h2>锐利思</h2></a>
          <!-- <img src="/imgs/logo.png" alt="" /> -->
        </div>
        <!-- 导航 -->
        <div class="n-list">
          <a href="/">网站首页</a>
          <a href="javascript:;" @click="goAc1()">传统软件</a>
          <a href="javascript:;" @click="goAc2()">独家资讯</a>
          <!-- <a href="javascript:;" @click="goAc3()">研究报告</a> -->
          <!-- <a href="javascript:;" @click="goAc4()">互联网</a> -->
          <!-- <a href="javascript:;" @click="goAc5()">业界资讯</a> -->
          <!-- <a href="javascript:;" @click="goAc6()">手机应用</a> -->
          <!-- <a href="/ac1">收费资讯</a> -->
          <a href="javascript:;" @click="goInfo()">关于我们</a>
          <!-- <a href="javascript:;" @click="goXy()">用户协议</a> -->
        </div>
        <!-- 联系我们 -->
        <!-- <div class="n-phone">
          <h2>联系电话：</h2>
          <span>021-61610870</span>
        </div> -->
        <!-- 登录注册 -->
        <div class="n-login">
          <span class="n-login" @click="goLogin()" v-show="this.LoggedShow"> 登录 </span>
          <span class="n-login" @click="goLogin()" v-show="this.LoggedShow"> 注册 </span>

          <!-- 用户名 -->
          <span class="n-info" v-show="!this.LoggedShow" v-text="this.nv_user.Username"
            ><i class="iconfont icon-denglu"></i
          ></span>

          <!-- 退出登录 -->
          <span class="n-info" @click="tolg()" v-show="!this.LoggedShow">
            <i class="iconfont icon-tuichudenglu"></i>
            退出登录
          </span>
        </div>
      </div>
    </div>


    <!-- 下层 -->
    <!-- <div class="n-con" >
     
      <div class="swiper-box">
        <swiper :options="swiperOption">
      
          <swiper-slide v-for="(item, index) in this.swiperList" :key="index">
            <a href="javascript:;"><img :src="item.img" /></a>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </div> -->
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import "swiper/css/swiper.css";
export default {
  components: {
    // Swiper,
    // SwiperSlide,
  },
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
      k: this.$route.query.k,
      swiperOption: {
        autoplay: true,
        loop: true,
        // effect: "cube",
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      // 轮播数组
      swiperList: [
        {
          id: "1",
          img: "/imgs/s1.jpg",
        },
        {
          id: "2",
          img: "/imgs/s2.jpg",
        },
      ],
    };
  },
  mounted() {
    this.logged();
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },
    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      if (this.k) {
        window.localStorage.setItem("state", "10");
        window.localStorage.removeItem("k");
        this.$router.push("/");
        location.reload();
      } else {
        window.localStorage.setItem("state", "10");
        this.$router.push("/");
        location.reload();
      }
    },

    // 收费资讯跳转
    goAc() {
       this.$router.push("/ac2");
    },
    // 关于我们跳转
    goInfo(){
       this.$router.push("/info");
    },
    // 用户协议跳转
    goXy(){
       this.$router.push("/xy");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.n-header {
  position: relative;
  width: 100%;
  z-index: 20;
  // 导航层
  .n-n {
    width: 100%;
    height: 80px;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    // margin-bottom: 80px;
    .container {
      height: 100%;
      @include flex();
      // logo
      .n-logo {
        width: 334px;
        height: 100%;
        display: flex;
        align-items: center;
        // img {
        //   width: 100%;
        //   height: 100%;
        // }
        h2 {
          font-size: 16px;
          // font-weight: 400;
          // line-height: 80px;
        }
      }
      // 导航
      .n-list {
        width: 80%;
        height: 100%;
        display: flex;
        align-items: center;
        a {
          font-size: 14px;
          flex: 1;
          //   margin: 0 15;
          display: inline-block;
          height: 80px;
          line-height: 80px;
          text-align: center;
          //   background-color: $colorZ;
          transition: all 0.2s;
          color: #333;
          &:hover {
            background-color: $colorZ;
            color: #fff;
          }
        }
      }

      // 联系我们
      .n-phone {
        height: 100%;
        display: flex;
        align-items: center;
        h2 {
          font-size: 14px;
          color: $colorZ;
        }
        span {
          font-size: 18px;
          cursor: pointer;
          transition: all 0.2s;
          &:hover {
            color: $colorZ;
          }
        }
      }
      // 登录注册
      .n-login {
        // width: 100%;
        height: 30px;
        //   background-color: #c60023;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          display: inline-block;
          cursor: pointer;
          width: 100px;
          // background: #fafafa;
          height: 100%;
          margin: 0 15px;
          text-align: center;
          line-height: 30px;
          border: 1px dashed $colorZ;
          font-size: 16px;
          transition: all 0.2s;
          &:hover {
            background-color: $colorZ;
            color: #fff;
          }
        }
      }
    }
  }

  //   下层容器
  .n-con {
    width: 100%;
    height: 500px;
    margin-top: 80px;
    z-index: 1;
    margin-bottom: 20px;
    .swiper-box {
      width: 100%;
      height: 100%;
      .swiper-container {
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    // .container {
    //   // background-color: pink;
    //   position: relative;
    //   height: 100%;
    //   @include flex();
    //   //   左侧

    //   // 右侧
    //   .n-r {
    //     width: 100%;
    //     height: 100%;
    //     // background-color: #c60023;
    //     img {
    //       width: 100%;
    //       height: 100%;
    //     }
    //   }
    // }
  }
}
</style>