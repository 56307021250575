
<template>
  <div class="login">
    <nav-header></nav-header>
    <!-- 导航栏 -->
    <div class="con" style="height: 100%">
      <div class="login_warp">
        <div class="loginbox">
          <!-- 三个选项卡按钮 -->
          <div class="login_header">
            <span @click="cur = 0" :class="{ active: cur == 0 }">账号登录</span>
            <span @click="cur = 1" :class="{ active: cur == 1 }">账号注册</span>
          </div>
          <div class="login_content">
            <!-- 在cur==0时此板块显示 其他时候此板块不显示 -->
            <div v-show="cur == 0" class="Cbody_item">
              <div class="form_item">
                <i class="iconfont icon-denglu"></i>
                <input
                  type="text"
                  name="fname"
                  placeholder="用户名"
                  v-model="lUsername"
                />
              </div>
              <div class="form_item">
                <i class="iconfont icon-jinru"></i>
                <input
                  type="password"
                  name="fpassword"
                  placeholder="密码"
                  v-model="lPassword"
                />
              </div>
              <div class="fr" @click="goAg()">
                <!-- <a
                href="javascript:;"
                style="color: #000; font-weight: bold; font-size: 14px"
                >《用户协议》</a
              > -->
              </div>
              <div class="clear"></div>
              <div class="form_item">
                <input type="submit" name="" value="登录" @click="logo()" />
              </div>
            </div>
            <!-- 在cur==1时此板块显示 其他时候此板块不显示 -->
            <div v-show="cur == 1" class="Cbody_item">
              <div class="form_item">
                <i class="iconfont icon-denglu"></i>
                <input
                  type="text"
                  name="fname"
                  placeholder="用户名"
                  v-model="rUsername"
                />
              </div>
              <div class="form_item">
                <i class="iconfont icon-jinru"></i>
                <input
                  type="password"
                  name="fpassword"
                  placeholder="密码"
                  v-model="rPassword"
                />
              </div>
              <div class="form_item">
                <!-- <div class="fl"><input type="checkbox">记住密码</div> -->
                <div class="fr" @click="goAg()">
                  <!-- <a
                  href="javascript:;"
                  style="color: #000; font-weight: bold; font-size: 14px"
                  >《用户协议》</a
                > -->
                </div>
              </div>
              <div class="clear"></div>
              <div class="form_item">
                <input type="submit" name="" value="注册" @click="register()" />
              </div>
            </div>
          </div>
        </div>
        <!-- 右边蓝色板块 -->
      </div>
    </div>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import NavFooter from "./../components/NavFooter";
import NavHeader from "./../components/N2";
export default {
  data() {
    return {
      cur: 0, //默认选中第一个tab
      // 用户名
      lUsername: "",
      // 密码
      lPassword: "",
      // 获取收费跳转参数
      k: this.$route.query.k,
      // 获取到 user 用户信息
      logo_user: JSON.parse(window.localStorage.getItem("user")),
      href: "index.html",
      rUsername: "",
      rPassword: "",
    };
  },
  components: {
    NavFooter,
    NavHeader,
  },
  mounted() {
    document.title = "锐利思";
  },
  methods: {
    // 登录逻辑
    logo() {
      if (this.k) {
        window.localStorage.setItem("state", "0");
        this.$router.push("/");
        // this.$router.push("/pay");
        window.location.reload();
      } else if (this.lUsername !== this.logo_user.Username) {
        alert("用户名错误");
      } else if (this.lPassword !== this.logo_user.Password) {
        alert("密码错误");
      } else {
        window.localStorage.setItem("state", "0");
        this.$router.push("/index");
        window.location.reload();
      }
    },
    goReg() {
      this.$router.push("/register");
    },
    // 注册逻辑
    register() {
      // 获取 user
      var ruser = {
        Username: this.rUsername,
        Password: this.rPassword,
      };
      // 判断非空
      if (!this.rUsername) {
        alert("请输入用户名");
        return;
      }
      if (!this.rPassword) {
        alert("请输入密码");
        return;
      }
      window.localStorage.setItem("state", "0");
      window.localStorage.setItem("user", JSON.stringify(ruser));
      this.$router.push("/#/index");
    },
    // 跳转注册协议
    goAg() {
      this.$router.push("/xy");
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  // background: pink;
  background: url("/imgs/ht.jpg") no-repeat center;
  background-size: cover;
  height: 500px;
  // margin-top: 15px;
  margin-top: 80px;
}

.fr {
  float: right;
}
.clear {
  clear: both;
}
a {
  text-decoration: none;
  color: #333;
  transition: ease all 0.5s;
}
a {
  color: #1c65ff;
}
.login_header {
  margin-bottom: 40px;
}
.login_header span {
  margin-right: 20px;
  cursor: pointer;
}
.loginbox {
  width: 300px;
  overflow: hidden;
  padding: 20px;
  padding-top: 15px;
  margin: 20px auto;
}
.Cbody_item {
  border: 0px solid #999;
  overflow: hidden;
}
.form_item {
  font-size: 13px;
  display: flex;
  align-items: center;
  i {
    width: 36px;
    height: 36px;
    font-size: 19px;
    // border: 1px dashed #ccc;
  }
}
.form_item input[type="text"],
.form_item input[type="password"] {
  display: block;
  width: calc(100% - 18px);
  height: 36px;
  margin: 0 auto;
  padding-left: 16px;
  outline: none;
  border: 1px solid #999;
  transition: ease all 0.5s;
  margin-bottom: 20px;
}
.form_item input[type="text"]:focus,
.form_item input[type="password"]:focus {
  border: 1px solid #1c65ff;
}
.form_item input[type="submit"] {
  display: block;
  width: calc(100% - 0px);
  height: 40px;
  margin: 0 auto;
  padding-left: 16px;
  outline: none;
  border: 1px solid #121212;
  transition: ease all 0.5s;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #121212;
  color: #fff;
  cursor: pointer;
  border-radius: 30px;
}
.form_item input[type="submit"]:hover {
  background-color: #121212;
  border: 1px solid #121212;
  color: #fff;
}
.active {
  // color: #63dbc1;
  color: #1c65ff;
  padding-bottom: 10px;
  border-bottom: 3px solid #1c65ff;
}
.loginrslider {
  width: 160px;
  height: 322px;
  background-color: #1c65ff;
}
.login_warp {
  width: 500px;
  // margin: 50px auto;
  border-radius: 10px;
  box-shadow: 0 0px 0px #ccc;
  overflow: hidden;
  // border: 1px solid #63dbc1;
  border: 1px solid #1c65ff;
  //   margin-top: 10%;
  transition: ease all 0.5s;
  position: relative;
  top: 60px;
  cursor: pointer;
  margin-left: 180px;
  background-color: rgba(2255, 255, 255, 0.6);
}
.login_warp:hover {
  top: 30px;
  box-shadow: 0 15px 21px #ccc;
}
.loginrslider {
  color: #fff;
}
.qcode {
  width: 160px;
  height: 160px;
  background-color: #ccc;
  margin: 0 auto;
  margin-top: 2px;
}
.beizhu {
  text-align: center;
  font-size: 13px;
  margin-top: 10px;
  color: #999;
}
</style>
